import IndexNavbar from 'components/Navbars/IndexNavbar'
import React from 'react'
import { Row, Col, Button } from "reactstrap"



const Banner = () => {


    return (
        <>
            <div id="home">
                <div className='banner-bg' >

                    <IndexNavbar />

                    <Row className='pt-5'>
                        <Col lg="12" className='pt-5' >
                            <div className='d-flex flex-column align-items-center justify-content-center pt-5 banner-text'>
                                <div className='text-center'>
                                    <h3 className=' mb-0'>THE LEADING <span>TikTok</span></h3>
                                    <h1 className=' mb-0'>PERFORMANCE </h1>
                                    <h1 className=' mb-0'>AGENCY </h1>
                                    <p className='mt-4'> We'll take over your Tiktok to drive profitable sales.</p>

                                </div>
                                <div className='mt-5 text-center'>
                                    <a href="/#pro_info" >
                                        <Button className='mt-4 mt-lg-0 px-4 mb-0 py-2 btn-submit'>BOOK A CALL NOW
                                            <span>
                                                <img src={require('../../assets/img/launch_img/arrow-right.png').default} className="img-fluid ml-3" />
                                            </span>
                                        </Button>
                                    </a>
                                </div>
                            </div>
                        </Col>

                    </Row>

                </div>
                <Row className="pt-5 mt-5">
                    <Col lg="4" className='text-center'>
                        <div className='d-flex flex-column million-text'>
                            <h3 className='mb-0 counter'>
                                $13M+
                            </h3>
                            <p>In ad spend</p>
                        </div>
                    </Col>
                    <Col lg="1" className='centered-border'>

                    </Col>
                    <Col lg="2" className='text-center  pt-5 pt-lg-0'>
                        <div className='d-flex flex-column million-text'>
                            <h3 className='mb-0 counter'>
                                400M+
                            </h3>
                            <p>Impressions</p>
                        </div>
                    </Col>
                    <Col lg="1"></Col>
                    <Col lg="4" className='text-center centered-border pt-5 pt-lg-0'>
                        <div className='d-flex flex-column million-text'>
                            <h3 className='mb-0 counter'>
                                7X+
                            </h3>
                            <p>Avg ROAS increase</p>
                        </div>
                    </Col>


                </Row>

            </div >

        </>
    )
}

export default Banner