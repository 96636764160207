import React, { useState, useEffect } from "react"
import {
    Row, Col, NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Collapse
} from "reactstrap"

import Footer from "components/Footer/Footer"
import { FaTimes } from 'react-icons/fa'


const Privacy_Terms = () => {

    const [collapseOpen, setCollapseOpen] = useState(false);
    const [collapseOut, setCollapseOut] = useState("");

    const toggleCollapse = () => {
        document.documentElement.classList.toggle("nav-open");
        setCollapseOpen(!collapseOpen);
    };
    const onCollapseExiting = () => {
        setCollapseOut("collapsing-out");
    };
    const onCollapseExited = () => {
        setCollapseOut("");
    };

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div >
            <div className="">
                <Navbar expand="xl" className="py-2 nav-privacy">
                    <Row className="w-100">
                        <Col lg="12" xl="2" className="px-0 px-xl-3">
                            <div className="d-flex w-100 justify-content-between ">

                                <NavbarBrand >
                                    <div className="d-flex align-items-center justify-content-center nav-privacy-text pl-5">
                                        <p>Launch</p>  <span className="ml-2">Bureau</span>
                                    </div>
                                </NavbarBrand>

                                <button
                                    aria-expanded={collapseOpen}
                                    className="navbar-toggler navbar-toggler"
                                    onClick={toggleCollapse}
                                >
                                    <img src={require('../../assets/img/launch_img/menu.png').default} height={30} width={30} className="img-fluid mr-4" />
                                </button>
                            </div>
                        </Col>
                        <Col lg="8" xl="10" className="pr-0 d-flex justify-content-end">
                            <Collapse
                                className={"ml-4 bg-black justify-content-end justify-content-lg-between " + collapseOut}
                                navbar
                                isOpen={collapseOpen}
                                onExiting={onCollapseExiting}
                                onExited={onCollapseExited}
                            >

                                <div className="navbar-collapse-header">
                                    <Row>
                                        <Col className="collapse-close" xs="6">
                                            <button
                                                aria-expanded={collapseOpen}
                                                className="navbar-toggler"
                                                onClick={toggleCollapse}
                                            >
                                                <FaTimes className="pf-text-secondary" color="white" size="24" />
                                            </button>

                                        </Col>
                                    </Row>
                                </div>

                                <Nav navbar className=" d-lg-none mobile-menu mr-4">
                                    <NavItem className="p-2">
                                        <NavLink
                                            data-placement="bottom"
                                            rel="noopener noreferrer"
                                        >
                                            <a href="/#home" rel='noreferrer' >Home</a>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className="p-2">
                                        <NavLink
                                            data-placement="bottom"
                                            rel="noopener noreferrer"
                                        >
                                            <a href="/#services" rel='noreferrer' >Our Services</a>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className="p-2">
                                        <NavLink
                                            data-placement="bottom"
                                            rel="noopener noreferrer"
                                        >
                                            <a href="/#handle" rel='noreferrer' >We'll Handle TikTok For Your</a>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className="p-2">
                                        <NavLink
                                            data-placement="bottom"
                                            rel="noopener noreferrer"
                                        >
                                            <a href="/#stories" rel='noreferrer' >Success Stories</a>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className="p-2">
                                        <NavLink
                                            data-placement="bottom"
                                            rel="noopener noreferrer"
                                        >
                                            <a href="/#ads" rel='noreferrer' >Create Ads That Convert</a>
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <div className="d-flex w-100 justify-content-between align-items-center">
                                    <div className="d-flex flex-column flex-xl-row w-100 justify-content-end">

                                        <div className="my-4 my-xl-0 d-lg-block d-none">

                                            <img src={require('../../assets/img/launch_img/menu.png').default} height={30} width={30} className="img-fluid mr-4" />

                                        </div>
                                    </div>

                                </div>
                            </Collapse>
                        </Col>
                    </Row>
                </Navbar>
            </div>

            <Row className="privacy-text">
                <Col lg="12" md="12" sm="12" xs="12">
                    <div className=" d-flex flex-column justify-content-left align-items-left" >
                        <h2 className="mb-0">
                            Launch Bureau Privacy Policy
                        </h2>

                        <p className="pt-4">
                            This Privacy Policy describes the policies and procedures of Launch Bureau LLC (“we”, “our” or “us”) on the collection, use and disclosure of your information on Launch Bureau.com and the services, features, content, applications, and products we offer (collectively, the “Application”). When you use the Services, you are consenting to the collection, transfer, manipulation, storage, disclosure and other uses of your information as described in this Privacy Policy.
                        </p>
                    </div>
                    <div className="text-left pt-4" >
                        <h3 >
                            TYPES OF DATA COLLECTED
                        </h3>
                        <p>
                            This Privacy Policy covers the treatment of personally identifiable information (“Personal Data”) gathered when you are using or accessing the Application. Among the types of Personal Data that this Application collects, by itself or through third parties, there are: Cookies, Usage Data and Email.

                        </p>
                        <p>
                            Other Personal Data collected may be described in other sections of this Privacy Policy or by dedicated explanation text contextually with the Personal Data collection.

                        </p>
                        <ul>
                            <li>  The Personal Data may be freely provided by the User, or collected automatically when using this Application.
                            </li>
                            <li> Any use of Cookies – or of other tracking tools – by this Application or by the owners of third party services used by this Application, unless stated otherwise, serves to identify Users and remember their preferences, for the sole purpose of providing the service required by the User.
                            </li>
                            <li>  Failure to provide certain Personal Data may make it impossible for this Application to provide its services.
                            </li>
                            <li> The User assumes responsibility for the Personal Data of third parties published or shared through this Application and declares to have the right to communicate or broadcast them, thus relieving the Data Controller of all responsibility.
                            </li>
                        </ul>
                    </div>
                    <div className="text-left" >
                        <h3>
                            METHODS AND PLACE OF PROCESSING
                        </h3>
                        <p>
                            The Data Controller processes the Personal Data of Users in a proper manner and shall take appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Personal Data.
                        </p>
                        <p>
                            The data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated.

                        </p>
                    </div>
                    <div className="text-left" >
                        <h3>
                            GOOGLE ANALYTICS (GOOGLE)
                        </h3>
                        <p>
                            Google Analytics is a web analysis service provided by Google Inc. (“Google”). Google utilizes the Personal Data collected to track and examine the use of this Application, to prepare reports on its activities and share them with other Google services.
                        </p>
                        <ul>
                            <li>
                                Google may use the Personal Data collected to contextualize and personalize the ads of its own advertising network.
                            </li>
                            <li>
                                Personal Data collected: Cookie and Usage Data.
                            </li>
                            <li>
                                Place of processing : USA – Privacy Policy – Opt Out
                            </li>
                        </ul>
                    </div>
                    <div className="text-left" >
                        <h3>
                            INTERACTION WITH EXTERNAL SOCIAL NETWORKS AND PLATFORMS
                        </h3>
                        <p>
                            These services allow interaction with social networks or other external platforms directly from the pages of this Application.
                        </p>
                        <ul>
                            <li>
                                The interaction and information obtained by this Application are always subject to the User’s privacy settings for each social network.
                            </li>
                            <li>
                                If a service enabling interaction with social networks is installed it may still collect traffic data for the pages where the service is installed, even when Users do not use it.
                                Twitter Tweet Button and Social Widgets (Twitter)
                            </li>
                            <li>
                                The Twitter Tweet button and social widgets are services allowing interaction with the Twitter social network provided by Twitter Inc.
                            </li>
                            <li>
                                Personal Data collected: Cookie and Usage Data.
                            </li>
                            <li>
                                Place of processing : USA
                                Facebook Like Button and Social Widgets (Facebook)
                            </li>
                            <li>
                                The Facebook Like button and social widgets are services allowing interaction with the Facebook social network provided by Facebook, Inc.
                            </li>
                            <li>
                                Personal Data collected: Cookie and Usage Data.
                            </li>
                            <li>
                                Place of processing : USA
                            </li>
                        </ul>
                    </div>

                    <div className="text-left" >
                        <h3>
                            ADVERTISING
                        </h3>
                        <p>
                            To help us deliver ads (remarketing), measure their performance, and make them more relevant to you based on criteria like your activity in our application we use cookies and pixels to tailor ads and measure their performance. Using these technologies, we can show you ads and evaluate their effectiveness. This helps us provide high-quality ads and content that might be more interesting to you.
                        </p>
                        <ul>
                            <li>
                                If you would like to change the way we collect data about you in Twitter, visit Twitter account settings, Do Not Track browser setting, and the opt-out choices of Twitter’s ads.
                            </li>
                            <li>
                                Our third-party advertising providers, including Google Analytics for Display Advertisers, also use these technologies for marketing on our behalf, including the delivery of interest-based ads and measurement of their performance.
                            </li>
                            <li>
                                If you do not want our advertising providers to use your visits to Twitter websites to show you personalized ads on our behalf, you can opt out using your Do Not Track browser setting.
                            </li>
                            <li>
                                You can also opt out of Google Analytics by installing Google’s opt-out browser add-on, and out of interest-based Google ads using Google’s Ads Settings. To opt out of activity-based ads in Facebook, visit your Facebook Ad Settings.
                            </li>
                        </ul>
                    </div>
                    <div className="text-left" >
                        <h3>
                            MANAGING EMAIL ADDRESSES AND SENDING MESSAGES
                        </h3>
                        <p>
                            These services make it possible to manage a database of subscription based email contacts to communicate with the User via email. The services are used to collect data concerning the date and time when the mail is viewed by the User, as well as when the User interacts with incoming mail, such as by clicking on links included in the email.
                        </p>
                        <p className="pt-2">MailJet
                        </p>
                        <ul>
                            <li>
                                MailJet is an email address management and message sending service provided by The Rocket Science Group, LLC d/b/a MailJet
                            </li>
                            <li>
                                Personal Data collected: Email address
                            </li>
                            <li>
                                Place of processing : United States
                            </li>
                        </ul>
                    </div>
                    <div className="text-left" >
                        <h3>
                            ADDITIONAL INFORMATION ABOUT DATA COLLECTION
                        </h3>
                        <h3>
                            LEGAL ACTION
                        </h3>
                        <p>
                            The User’s Personal Data may be used for legal purposes by the Data Controller, in Court or in the stages leading to possible legal action arising from improper use of this Application or the related services. The User is aware of the fact that the Data Controller may be required to reveal Personal Data upon request of public authorities.
                        </p>
                    </div>
                    <div className="text-left" >
                        <h3>
                            ADDITIONAL INFORMATION ABOUT USERS’ PERSONAL DATA
                        </h3>
                        <p>
                            In addition to the information contained in this Privacy Policy, this Application may provide the User with additional and contextual information concerning particular services or the collection and processing of Personal Data upon request.
                        </p>
                    </div>
                    <div className="text-left" >
                        <h3>
                            SYSTEM LOGS AND MAINTENANCE
                        </h3>
                        <p>
                            For operation and maintenance purposes, this Application and any third party services may collect files that record interaction with this Application (System Logs) or use for this purpose other Personal Data (such as IP Address).
                        </p>
                    </div>
                    <div className="text-left" >
                        <h3>
                            INFORMATION NOT CONTAINED IN THIS PRIVACY POLICY
                        </h3>
                        <p>
                            More details concerning the collection or processing of Personal Data may be requested from the Data Controller at any time. Please see the contact information at the end of this Privacy Policy.
                        </p>
                    </div>
                    <div className="text-left" >
                        <h3>
                            THE RIGHTS OF USERS
                        </h3>
                        <p>
                            Users have the right, at any time, to know whether their Personal Data has been stored and can consult the Data Controller to learn about their contents and origin, to verify their accuracy or to ask for them to be supplemented, canceled, updated or corrected, or for their transformation into anonymous format or to block any data held in violation of the law, as well as to oppose their treatment for any and all legitimate reasons. Requests should be sent to the Data Controller at the contact information set out below. This Application does not support “Do Not Track” requests. To determine whether any of the third party services it uses honor the “Do Not Track” requests, please read their privacy policies.
                        </p>
                    </div>
                    <div className="text-left" >
                        <h3>
                            CHANGES TO THIS PRIVACY POLICY
                        </h3>
                        <p>
                            The Data Controller reserves the right to make changes to this Privacy Policy at any time by giving notice to its Users on this page. It is strongly recommended to check this page often, referring to the date of the last modification listed at the bottom. If a User objects to any of the changes to this Privacy Policy, the User must cease using this Application and can request that the Data Controller erase the Personal Data. Unless stated otherwise, the then-current privacy policy applies to all Personal Data the Data Controller has about Users.
                        </p>
                    </div>
                    <div className="text-left" >
                        <h3>
                            INFORMATION ABOUT THIS PRIVACY POLICY
                        </h3>
                        <p>
                            The Data Controller is responsible for this Privacy Policy.
                        </p>
                    </div>
                    <div className="text-left" >
                        <h3>
                            DATA CONTROLLER AND OWNER
                        </h3>
                        <p>
                            Launch Bureau LLC
                        </p>
                        <p>
                            1510 Bay Rd. Miami Beach, FL 33139
                        </p>
                        <p>
                            support@launchbureau.com
                        </p>
                        <p>
                            Copyright © 2023 Launch Bureau LLC All rights reserved.
                        </p>
                        <p>
                            Updated by Launch Bureau LLC on March 1, 2023
                        </p>
                    </div>
                </Col>
            </Row>
            <Footer />
        </div>




    )
}

export default Privacy_Terms