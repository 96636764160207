import React from "react";

import Banner from "./Banner";
import Services from "./Services";
import Handle from "./Handle";
import Stories from "./Stories";
import Ads from "./Ads";
import Pro_Info from "./Pro_Info"
import Footer from "components/Footer/Footer";

const Home = () => {
    return (
        <>
            <Banner />
            <Services />
            <Handle />
            <Stories />
            <Ads />
            <Pro_Info />
            <Footer />
        </>
    )
}

export default Home