import React from 'react'
import { Col, Row } from 'reactstrap'

const Ads = () => {
    return (
        <>

            <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" id="ads" >
                <Row className='pt-5 mt-5 '>
                    <Col lg="12">
                        <div className='d-flex flex-column align-items-center justify-content-center text-center ad-text'>

                            <h3>
                                CREATE ADS THAT <span className='ad-convert px-3 py-2'>CONVERT</span>
                            </h3>

                            <img src={require('../../assets/img/launch_img/ads.png').default} className="img-fluid pl-1 pl-lg-4 pr-1 pr-lg-4" />
                        </div>

                    </Col>
                </Row>

            </div>


        </>
    )
}
export default Ads