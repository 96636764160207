import React from 'react'
import { Col, Row } from 'reactstrap'
import Carousel from "react-elastic-carousel";


const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 4 },
    { width: 1200, itemsToShow: 5 }
]

const Stories = () => {
    return (
        <>
            <div data-aos="fade-up"
                data-aos-anchor-placement="top-bottom">
                <div className='success-bg pb-5' id="stories">
                    <Row className='pt-5 mt-5'>
                        <Col lg="12">
                            <div className='d-flex flex-column align-items-center justify-content-center story-text text-center'>
                                <h2 className='mb-5'>
                                    SUCCESS <span className='story py-2 px-3'>STORIES</span>
                                </h2>

                                <Carousel breakPoints={breakPoints}>
                                    <div className='story-cover-img'>
                                        <div className="d-flex justify-content-center align-items-center story-img " >
                                            <img src={require("../../assets/img/launch_img/story1.png").default} alt="img" className="img-fluid px-2 py-2" />

                                        </div>
                                        <div className='story-caro-text text-center pt-2'>
                                            <h4 className='mb-0'>
                                                GAINED 60K FOLLOWERS
                                            </h4>
                                            <p className=' text-center pt-2'>
                                                We worked with accounts in the beauty industry to scale reach and followers by 15x.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='story-cover-img'>
                                        <div className="d-flex justify-content-center align-items-center story-img" >
                                            <img src={require("../../assets/img/launch_img/story2.png").default} alt="img" className="img-fluid px-2 py-2" />
                                        </div>
                                        <div className='story-caro-text text-center pt-2'>
                                            <h4 className='mb-0'>
                                                GENERATED CONTENT AWARENESS
                                            </h4>
                                            <p className=' text-center pt-2'>
                                                Powered multiple influencer accounts to grow their brand and sales.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-center align-items-center story-img" >
                                        <img src={require("../../assets/img/launch_img/story3.png").default} alt="img" className="img-fluid px-2 py-2" />
                                    </div>
                                    <div className='story-cover-img'>
                                        <div className="d-flex justify-content-center align-items-center story-img" >
                                            <img src={require("../../assets/img/launch_img/story4.png").default} alt="img" className="img-fluid px-2 py-2" />
                                        </div>
                                        <div className='story-caro-text text-center pt-2'>
                                            <h4 className='mb-0'>
                                                SCALED PROFESSIONAL BRANDS
                                            </h4>
                                            <p className=' text-center pt-2'>
                                                We worked with multiple name-brand companies to grow their audience via TikTok ads.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='story-cover-img'>
                                        <div className="d-flex justify-content-center align-items-center  story-img" >
                                            <img src={require("../../assets/img/launch_img/story5.png").default} alt="img" className="img-fluid px-2 py-2" />
                                        </div>
                                        <div className='story-caro-text text-center pt-2'>
                                            <h4 className='mb-0'>
                                                5X PRODUCT SALES
                                            </h4>
                                            <p className=' text-center pt-2'>
                                                Have a product you’re selling? Promoting on TikTok is highly effective if done right.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='story-cover-img'>
                                        <div className="d-flex justify-content-center align-items-center story-img " >
                                            <img src={require("../../assets/img/launch_img/story1.png").default} alt="img" className="img-fluid px-2 py-2" />

                                        </div>
                                        <div className='story-caro-text text-center pt-2'>
                                            <h4 className='mb-0'>
                                                GAINED 60K FOLLOWERS
                                            </h4>
                                            <p className=' text-center pt-2'>
                                                We worked with accounts in the beauty industry to scale reach and followers by 15x.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='story-cover-img'>
                                        <div className="d-flex justify-content-center align-items-center story-img" >
                                            <img src={require("../../assets/img/launch_img/story2.png").default} alt="img" className="img-fluid px-2 py-2" />
                                        </div>
                                        <div className='story-caro-text text-center pt-2'>
                                            <h4 className='mb-0'>
                                                GENERATED CONTENT AWARENESS
                                            </h4>
                                            <p className=' text-center pt-2'>
                                                Powered multiple influencer accounts to grow their brand and sales.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-center align-items-center story-img" >
                                        <img src={require("../../assets/img/launch_img/story3.png").default} alt="img" className="img-fluid px-2 py-2" />
                                    </div>
                                    <div className='story-cover-img'>
                                        <div className="d-flex justify-content-center align-items-center story-img" >
                                            <img src={require("../../assets/img/launch_img/story4.png").default} alt="img" className="img-fluid px-2 py-2" />
                                        </div>
                                        <div className='story-caro-text text-center pt-2'>
                                            <h4 className='mb-0'>
                                                SCALED PROFESSIONAL BRANDS
                                            </h4>
                                            <p className=' text-center pt-2'>
                                                We worked with multiple name-brand companies to grow their audience via TikTok ads.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='story-cover-img'>
                                        <div className="d-flex justify-content-center align-items-center  story-img" >
                                            <img src={require("../../assets/img/launch_img/story5.png").default} alt="img" className="img-fluid px-2 py-2" />
                                        </div>
                                        <div className='story-caro-text text-center pt-2'>
                                            <h4 className='mb-0'>
                                                5X PRODUCT SALES
                                            </h4>
                                            <p className=' text-center pt-2'>
                                                Have a product you’re selling? Promoting on TikTok is highly effective if done right.
                                            </p>
                                        </div>
                                    </div>

                                    <div className='story-cover-img'>
                                        <div className="d-flex justify-content-center align-items-center story-img " >
                                            <img src={require("../../assets/img/launch_img/story1.png").default} alt="img" className="img-fluid px-2 py-2" />

                                        </div>
                                        <div className='story-caro-text text-center pt-2'>
                                            <h4 className='mb-0'>
                                                GAINED 60K FOLLOWERS
                                            </h4>
                                            <p className=' text-center pt-2'>
                                                We worked with accounts in the beauty industry to scale reach and followers by 15x.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='story-cover-img'>
                                        <div className="d-flex justify-content-center align-items-center story-img" >
                                            <img src={require("../../assets/img/launch_img/story2.png").default} alt="img" className="img-fluid px-2 py-2" />
                                        </div>
                                        <div className='story-caro-text text-center pt-2'>
                                            <h4 className='mb-0'>
                                                GENERATED CONTENT AWARENESS
                                            </h4>
                                            <p className=' text-center pt-2'>
                                                Powered multiple influencer accounts to grow their brand and sales.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-center align-items-center story-img" >
                                        <img src={require("../../assets/img/launch_img/story3.png").default} alt="img" className="img-fluid px-2 py-2" />
                                    </div>
                                    <div className='story-cover-img'>
                                        <div className="d-flex justify-content-center align-items-center story-img" >
                                            <img src={require("../../assets/img/launch_img/story4.png").default} alt="img" className="img-fluid px-2 py-2" />
                                        </div>
                                        <div className='story-caro-text text-center pt-2'>
                                            <h4 className='mb-0'>
                                                SCALED PROFESSIONAL BRANDS
                                            </h4>
                                            <p className=' text-center pt-2'>
                                                We worked with multiple name-brand companies to grow their audience via TikTok ads.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='story-cover-img'>
                                        <div className="d-flex justify-content-center align-items-center  story-img" >
                                            <img src={require("../../assets/img/launch_img/story5.png").default} alt="img" className="img-fluid px-2 py-2" />
                                        </div>
                                        <div className='story-caro-text text-center pt-2'>
                                            <h4 className='mb-0'>
                                                5X PRODUCT SALES
                                            </h4>
                                            <p className=' text-center pt-2'>
                                                Have a product you’re selling? Promoting on TikTok is highly effective if done right.
                                            </p>
                                        </div>
                                    </div>


                                </Carousel>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

        </>
    )
}
export default Stories










