import React from 'react'
import { Col, Row, Button } from 'reactstrap'
import Carousel from "react-elastic-carousel";

const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 }
]

const Services = () => {
    return (
        <section data-aos="fade-up"
            data-aos-anchor-placement="top-bottom" id="services">
            <Row className=" pt-4 ">
                <Row className='service-text pt-5 pb-5'>
                    <Col lg="1"></Col>
                    <Col lg="4">
                        <div className='d-flex justity-content-lg-left justify-content-center align-items-center '>
                            <h3 className='mb-0'>
                                OUR
                            </h3>
                            <span className='service ml-3 px-3 text-center'>
                                SERVICES
                            </span>
                        </div>
                    </Col>
                    <Col lg="2"></Col>
                    <Col lg="4" className='text-justify pt-5 pt-lg-0'>
                        <p>
                            We’re a team of expert TikTok marketers, designers, and content creators. TikTok is the world’s leading social media platform and can be very powerful if done right. Our goal is to setup, manage, and optimize your organic and paid ads accounts to maximize your TikTok goals.

                        </p>
                    </Col>
                </Row>
                {/* <div className='drag-img'>
                    <img src={require('../../assets/img/launch_img/drag-caro.png').default} height={"60%"} width={"60%"} className="img-fluid ml-3" />
                </div> */}
                <Carousel breakPoints={breakPoints} className=''>

                    <div className="card d-flex flex-column justify-content-left align-items-left m-2 pb-2" width="18 rem" >

                        <img className="card-img-top img-fluid p-3" src={require("../../assets/img/launch_img/caro1.png").default} alt="img" />
                        <div className="card-body ">
                            <h5 className="card-title">FULL TIKTOK ACCOUNT MANAGEMENT</h5>
                            <p className="card-text ">We’ll setup, manage, and optimize your entire account for maximum performance. This includes organic posts and tagging, A/B testing, content creation tailored specifically to your brand and account, paid ads management, and more.</p>
                        </div>
                        {/* <div className='caro-call mt-2 mb-4 pl-3'>
                            <p>BOOK A CALL NOW
                                <span>
                                    <img src={require('../../assets/img/launch_img/arrow-black.png').default} className="img-fluid ml-3" />
                                </span>
                            </p>
                        </div> */}
                    </div>


                    <div className="card d-flex flex-column justify-content-left align-items-left m-2 pb-2" width="18 rem" >

                        <img className="card-img-top img-fluid p-3" src={require("../../assets/img/launch_img/caro2.png").default} alt="img" />
                        <div className="card-body">
                            <h5 className="card-title">TIKTOK ADS</h5>
                            <p className="card-text">Our performance marketers will setup, manage, and optimize your TikTok paid ads based on your business goals and sales targets. We have a consistent, proven formula that involves A/B testing and cycle optimization.
                            </p>
                        </div>
                        {/* <div className='caro-call  mt-2 mb-4 pl-3'>
                            <p>BOOK A CALL NOW
                                <span>
                                    <img src={require('../../assets/img/launch_img/arrow-black.png').default} className="img-fluid ml-3" />
                                </span>
                            </p>
                        </div> */}
                    </div>

                    <div className="card d-flex flex-column justify-content-left align-items-left m-2 pb-3" width="18 rem" >

                        <img className="card-img-top img-fluid p-3" src={require("../../assets/img/launch_img/caro3.png").default} alt="img" />
                        <div className="card-body pb-4">
                            <h5 className="card-title">CONTENT CREATION</h5>
                            <p className="card-text">We work with popular influencers across many verticals to create attention-grabbing content that converts. We’ll work with you to create unique video content that matches your product or brand.
                            </p>
                        </div>
                        {/* <div className='caro-call mt-2 mb-4 pl-3' >
                            <p>BOOK A CALL NOW
                                <span>
                                    <img src={require('../../assets/img/launch_img/arrow-black.png').default} className="img-fluid ml-3" />
                                </span>
                            </p>
                        </div> */}
                    </div>

                    <div className="card d-flex flex-column justify-content-left align-items-left m-2 pb-2" width="18 rem" >

                        <img className="card-img-top img-fluid p-3" src={require("../../assets/img/launch_img/caro1.png").default} alt="img" />
                        <div className="card-body ">
                            <h5 className="card-title">FULL TIKTOK ACCOUNT MANAGEMENT</h5>
                            <p className="card-text ">We’ll setup, manage, and optimize your entire account for maximum performance. This includes organic posts and tagging, A/B testing, content creation tailored specifically to your brand and account, paid ads management, and more.</p>
                        </div>
                    </div>

                    <div className="card d-flex flex-column justify-content-left align-items-left m-2 pb-2" width="18 rem" >

                        <img className="card-img-top img-fluid p-3" src={require("../../assets/img/launch_img/caro2.png").default} alt="img" />
                        <div className="card-body">
                            <h5 className="card-title">TIKTOK ADS</h5>
                            <p className="card-text">Our performance marketers will setup, manage, and optimize your TikTok paid ads based on your business goals and sales targets. We have a consistent, proven formula that involves A/B testing and cycle optimization.
                            </p>
                        </div>
                    </div>
                    <div className="card d-flex flex-column justify-content-left align-items-left m-2 pb-3" width="18 rem" >

                        <img className="card-img-top img-fluid p-3" src={require("../../assets/img/launch_img/caro3.png").default} alt="img" />
                        <div className="card-body pb-4">
                            <h5 className="card-title">CONTENT CREATION</h5>
                            <p className="card-text">We work with popular influencers across many verticals to create attention-grabbing content that converts. We’ll work with you to create unique video content that matches your product or brand.
                            </p>
                        </div>
                    </div>
                    <div className="card d-flex flex-column justify-content-left align-items-left m-2 pb-2" width="18 rem" >

                        <img className="card-img-top img-fluid p-3" src={require("../../assets/img/launch_img/caro1.png").default} alt="img" />
                        <div className="card-body ">
                            <h5 className="card-title">FULL TIKTOK ACCOUNT MANAGEMENT</h5>
                            <p className="card-text ">We’ll setup, manage, and optimize your entire account for maximum performance. This includes organic posts and tagging, A/B testing, content creation tailored specifically to your brand and account, paid ads management, and more.</p>
                        </div>
                    </div>

                    <div className="card d-flex flex-column justify-content-left align-items-left m-2 pb-2" width="18 rem" >

                        <img className="card-img-top img-fluid p-3" src={require("../../assets/img/launch_img/caro2.png").default} alt="img" />
                        <div className="card-body">
                            <h5 className="card-title">TIKTOK ADS</h5>
                            <p className="card-text">Our performance marketers will setup, manage, and optimize your TikTok paid ads based on your business goals and sales targets. We have a consistent, proven formula that involves A/B testing and cycle optimization.
                            </p>
                        </div>
                    </div>
                    <div className="card d-flex flex-column justify-content-left align-items-left m-2 pb-3" width="18 rem" >

                        <img className="card-img-top img-fluid p-3" src={require("../../assets/img/launch_img/caro3.png").default} alt="img" />
                        <div className="card-body pb-4">
                            <h5 className="card-title">CONTENT CREATION</h5>
                            <p className="card-text">We work with popular influencers across many verticals to create attention-grabbing content that converts. We’ll work with you to create unique video content that matches your product or brand.
                            </p>
                        </div>
                    </div>

                </Carousel>
            </Row>
            
        </section>
    )
}

export default Services