/*!

=========================================================
* Launch Bureau - v1.0.0
=========================================================
* Coded by Ecrubit
*/
import React, {useEffect} from "react";
// reactstrap components
import {
  Row,
  Col
} from "reactstrap";
import { Link } from 'react-router-dom'

export default function Footer() {

  const AnchorScroll = () => {
    setTimeout(() => {
      let currentLocation = window.location.href;
      const hasAnchor = currentLocation.includes("/#");
      if (hasAnchor) {
        const anchorId = `${currentLocation.substring(currentLocation.indexOf("#") + 1)}`;
        const anchorElement = document.getElementById(anchorId);
        if (anchorElement) {
          setTimeout(() => {
            anchorElement.scrollIntoView({ behavior: "smooth" });
          }, 200);
        }
      } else {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        })
      }
    }, 200);
  }

  return (
    <section data-aos="flip-up" className="foot-bg pb-2">

      <Row className="d-flex justify-content-between">
        <Col md="1" lg="1"></Col>
        <Col md="7" lg="7" className="d-flex align-items-center justify-content-center text-center footer-text pt-3 pt-lg-0 pb-3 pb-lg-0">
          <h2 className="mb-0">
            IT'S TIME TO GROW YOUR

            <span className="ml-4 foot-logo-text px-4 ">
              TikTok
            </span>
          </h2>

        </Col>
        <Col md="4" lg="4" className="text-center">
          <div className="pb-lg-5 pb-0 text-md-center text-lg-right">

            <img src={require('../../assets/img/launch_img/footer-img.png').default} className="img-fluid" height={"100%"} width={"100%"} />


          </div>
        </Col>
        <Col md="4" lg="4" className=" pb-3 ">

        </Col>
      </Row >
      <Row>
        <Col md="12" lg="12" className="text-center d-flex flex-column align-items-center justify-content-center foot-nav footer-border">
          <div className="text-center d-flex pb-4 pt-4">
            <h5 className="mb-0">Launch</h5>
            <span className="ml-2">Bureau</span>
          </div>

          <div className="d-flex flex-lg-row flex-column align-items-center justify-content-center pb-5 footer-nav-text">

            <Link to="/" onClick={AnchorScroll}>
              <p className="ml-lg-5 ml-0 ">
                Home
              </p>
            </Link>

            <a href="/#services" >
              <p className="ml-lg-5 ml-0 mt-2 mt-lg-0" >
                Our Services
              </p>
            </a>

            <a href="/#handle" >
              <p className="ml-lg-5 ml-0 mt-2 mt-lg-0">
                We'll Handle TikTok For Your
              </p>
            </a>

            <a href="/#stories" >
              <p className="ml-lg-5 ml-0 mt-2 mt-lg-0">
                Success Stories
              </p>
            </a>

            <a href="/#ads" >
              <p className="ml-lg-5 ml-0 mt-2 mt-lg-0">
                Create Ads That Convert
              </p>
            </a>

          </div>
        </Col>
      </Row>

      <Row className="justify-content-center footer-border pb-lg-3 pb-0">

        <Col md="5" lg="5" className="text-lg-left text-center foot-text pt-3 ">
          <p className="mb-0 ">© Copyright 2023 Launch Bureau.All Rights Reserved.</p>
        </Col>
        <Col md="1" lg="1" className="text-center mt-lg-3 mt-0">

        </Col>
        <Col md="4" lg="4" className="mt-lg-3 mt-0 foot-text ">
            <div className="d-flex justify-content-center justify-content-lg-end pt-3 pt-lg-0 ">
              <Link to="/privacy-policy"><p className="pr-lg-3 pr-0" onClick={AnchorScroll}>Privacy policy</p></Link>
              <p className="d-none d-lg-block">|</p>
              <Link to="/terms-condition"><p className="pl-lg-3 pr-lg-5 pr-0" onClick={AnchorScroll}>Terms of services</p></Link>
            </div>
        </Col>
        {/* <Col lg="2" md="2" className="d-flex align-items-center justify-content-center pt-3 pt-lg-0">
          <div className=" d-flex ">
            <a href="#" className="ml-3">
              <img src={require('../../assets/img/launch_img/tiktok.png').default} className="img-fluid" height={"70%"} width={"70%"} />
            </a>
            <a href="https://twitter.com/" className="ml-3">
              <img src={require('../../assets/img/launch_img/twitter.png').default} className="img-fluid" height={"70%"} width={"70%"} />
            </a>
            <a href="https://discord.gg/" className="ml-3">
              <img src={require('../../assets/img/launch_img/insta.png').default} className="img-fluid" height={"70%"} width={"70%"} />
            </a>
          </div>
        </Col> */}
      </Row>
    </section >
  );
}
