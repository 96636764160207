import React from 'react'
import { Route, Switch, BrowserRouter } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
// Custom

import Index from "views/home";
import Privacy_Terms from 'views/home/Privacy_Terms';
import FormSubmission from 'views/home/FormSubmission';

export default function Routes() {
    React.useEffect(() => {
        AOS.init();
        window.scrollTo(0, 0);
    });
    return (
        <>
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" render={(props) => <Index {...props} />} />
                    <Route exact path="/privacy-policy" render={(props) => <Privacy_Terms {...props} />} />
                    <Route exact path="/terms-condition" render={(props) => <Privacy_Terms {...props} />} />
                    <Route exact path="/formsubmitted" render={(props) => <FormSubmission {...props} />} />
                </Switch>
            </BrowserRouter>
        </>
    )
}