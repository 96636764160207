import IndexNavbar from 'components/Navbars/IndexNavbar'
import React, { useEffect } from 'react'
import { Button } from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'
import { BsFillTelephoneFill } from 'react-icons/bs'
import Footer from 'components/Footer/Footer'

const FormSubmission = () => {
    const history = useHistory()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="bg-dark form-submission">
            <IndexNavbar />
            <section className='bg-dark d-flex align-items-center justify-content-center '>
                <div className='w-fitcontent d-flex flex-column align-items-center justify-content-center step-success mx-auto px-2 px-sm-5 py-4'>
                    <p className='text-white text-center mb-2 font-weight-bold w-fitcontent'>Thanks! Our team will be reaching out shortly via email.</p>
                    <p className='text-white text-center mb-0 font-weight-bold'>You can also book a call with us now:</p>
                    <a className='mt-5 px-3 px-sm-5 py-3 bg-white' href='https://calendly.com/davidlaunchbureau/call' target={'_blank'}>
                        <div className='d-flex align-items-center justify-content-center'>
                            <span><BsFillTelephoneFill color='#FE2858' size={20} /><span className='ml-3'>BOOK A CALL!</span></span></div>
                    </a>
                    <Link className='text-center mt-5 text-white ff-reg back-home' to='/'>Back to home</Link>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default FormSubmission