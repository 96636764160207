/*!

=========================================================
* carbon_cowboys - v1.0.0
=========================================================
* Coded by Ecrubit
*/
import React from "react";
import { Link } from "react-router-dom";
import { AnchorScroll } from "helper";
// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

import { FiMenu } from 'react-icons/fi';
import { FaTimes } from 'react-icons/fa'

export default function IndexNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [collapseOut, setCollapseOut] = React.useState("");

  const toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(!collapseOpen);
  };
  const onCollapseExiting = () => {
    setCollapseOut("collapsing-out");
  };
  const onCollapseExited = () => {
    setCollapseOut("");
  };

  return (
    <div className=" pt-3">
      <div className="">
        <Navbar expand="xl" className="py-0">
          <Row className="w-100">
            <Col lg="12" xl="2" className="px-0 px-xl-3">
              <div className="d-flex w-100 justify-content-between ">

                <NavbarBrand to="/" tag={Link} id="navbar-brand" onClick={() => AnchorScroll()}>
                  <div className="d-flex align-items-center justify-content-center nav-text-size  pl-4">
                    <p>Launch</p>  <span className="ml-2">Bureau</span>
                  </div>
                </NavbarBrand>

                <button
                  aria-expanded={collapseOpen}
                  className="navbar-toggler navbar-toggler"
                  onClick={toggleCollapse}
                >
                  {/* <FiMenu className="text-white" size="24" /> */}
                  <img src={require('../../assets/img/launch_img/menu.png').default} height={30} width={30} className="img-fluid" />
                </button>
              </div>
            </Col>
            <Col lg="8" xl="10" className="pr-0 d-flex justify-content-end">
              <Collapse
                className={"ml-4 bg-black justify-content-end justify-content-lg-between " + collapseOut}
                navbar
                isOpen={collapseOpen}
                onExiting={onCollapseExiting}
                onExited={onCollapseExited}
              >

                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-close" xs="6">
                      <button
                        aria-expanded={collapseOpen}
                        className="navbar-toggler"
                        onClick={toggleCollapse}
                      >
                        <FaTimes className="pf-text-secondary" color="white" size="24" />
                      </button>

                    </Col>
                  </Row>
                </div>

                <Nav navbar className=" d-lg-none mobile-menu mr-4">
                  <NavItem className="p-2">
                    <NavLink
                      data-placement="bottom"
                      rel="noopener noreferrer"
                    >
                      <a href="#home" rel='noreferrer' >Home</a>
                    </NavLink>
                  </NavItem>
                  <NavItem className="p-2">
                    <NavLink
                      data-placement="bottom"
                      rel="noopener noreferrer"
                    >
                      <a href="#services" rel='noreferrer' >Our Services</a>
                    </NavLink>
                  </NavItem>
                  <NavItem className="p-2">
                    <NavLink
                      data-placement="bottom"
                      rel="noopener noreferrer"
                    >
                      <a href="#handle" rel='noreferrer' >We'll Handle TikTok For Your</a>
                    </NavLink>
                  </NavItem>
                  <NavItem className="p-2">
                    <NavLink
                      data-placement="bottom"
                      rel="noopener noreferrer"
                    >
                      <a href="#stories" rel='noreferrer' >Success Stories</a>
                    </NavLink>
                  </NavItem>
                  <NavItem className="p-2">
                    <NavLink
                      data-placement="bottom"
                      rel="noopener noreferrer"
                    >
                      <a href="#ads" rel='noreferrer' >Create Ads That Convert</a>
                    </NavLink>
                  </NavItem>
                </Nav>

                <div className="d-flex w-100 justify-content-between align-items-center">
                  <div className="d-flex flex-column flex-xl-row w-100 justify-content-end">

                    <div className="my-4 my-xl-0 d-lg-block d-none">

                      <img src={require('../../assets/img/launch_img/menu.png').default} height={30} width={30} className="img-fluid mr-4" />

                    </div>
                  </div>

                </div>
              </Collapse>
            </Col>
          </Row>
        </Navbar>
      </div>
    </div >
  );
}
