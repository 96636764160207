import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Col, Row } from 'reactstrap'

const Handle = () => {
    return (
        <>
            <div data-aos="fade-up" id="handle">
                <div>
                    <Row className='pt-lg-5 mt-lg-5 d-flex align-items-center justify-content-center text-center'>
                        <Col lg="2"></Col>
                        <Col lg="8">

                            <div className='d-flex flex-column align-items-center justify-content-center handle-text'>
                                <div>
                                    <h3>
                                        WE'LL HANDLE <span className='handle-tiktok px-4 py-1'>TikTok</span> FOR YOU
                                    </h3>
                                    <p className='text-center'>
                                        We’ve managed and helped grow TikTok accounts from 0 to millions of followers, and countless sales. If you’re looking for a team to grow your TikTok and drive profitable sales and follower numbers, we’re the team to do it.

                                    </p>
                                </div>


                                <div className='mt-2 px-2 py-2'>
                                    <a href="/#pro_info" >
                                        <Button className='btn-submit mb-0'>BOOK A CALL NOW
                                            <span className='d-flex align-items-center'>
                                                <img src={require('../../assets/img/launch_img/arrow-right.png').default} className="img-fluid ml-3" />
                                            </span>
                                        </Button>
                                    </a>
                                </div>
                            </div>


                        </Col>
                        <Col lg="2"></Col>
                        <div className='d-flex align-items-center justify-content-center text-center'>
                            <img src={require('../../assets/img/launch_img/handle.png').default} className="img-fluid " />
                        </div>

                    </Row>

                </div>
            </div>

        </>
    )
}
export default Handle