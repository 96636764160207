import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'
import { Form, FormGroup, Input, Label, Button } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
// import { OpenNotification } from '@src/views/components/Helper'


const Pro_Info = () => {

    const { register, formState: { errors }, handleSubmit, reset } = useForm()
    const [btnSubmitted, setBtnSubmitted] = useState(false)

    const history = useHistory()

    const onSubmit = (data) => {

        const params = {
            name: data.name,
            email: data.email,
            phone: data.phone,
            projectUrl: data.projectUrl,
            projectBudget: data.projectBudget,
            aboutProject: data.aboutProject
        }
       
        setBtnSubmitted(true)
        fetch('https://api.launchbureau.com/data/projectDetails', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        })
            .then((response) => response.json())
            .then((res) => {
                setBtnSubmitted(false)
                reset()
                if (res.status === 'success') {
                    history.push('/formsubmitted')
                }
            })
            .catch((error) => {
                setBtnSubmitted(false)
                console.error(error)
            });
    }
    return (
        <>
            <section data-aos="fade-down" id="pro_info">
                <div>
                    <Row className='pt-5 mt-5 pro-text'>
                        <Col lg="12">
                            <div className='d-flex flex-column align-items-center justify-content-center text-center'>

                                <h3 className='mb-0'>
                                    SUBMIT YOUR PROJECT INFO
                                </h3>
                                <h3>
                                    AND WE'LL REACH OUT SHORTLY!
                                </h3>

                            </div>
                        </Col>

                    </Row>

                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col lg="1">
                            </Col>
                            <Col lg="5">
                                <div className='d-flex flex-column  '>
                                    <Label for='name'>
                                        Name*
                                    </Label>
                                    <div>
                                        <input type="text" {...register('name', { required: 'Name is required' })} id="name" name="name"
                                            className="line-input w-100" />
                                        {errors.name && <p className='text-danger mb-0'>{errors.name.message}</p>}
                                    </div>
                                    <Label for='phone' className='mt-4'>
                                        Phone
                                    </Label>
                                    <div>
                                        <input type="text" {...register('phone', { required: false })} id="phone" name="phone"
                                            className="line-input w-100" />
                                    </div>
                                </div>
                            </Col>

                            <Col lg="5" className='mt-4 mt-lg-0'>
                                <div className='d-flex flex-column '>
                                    <Label for='email'>
                                        Email*
                                    </Label>
                                    <div>
                                        <input type="text" {...register('email', { required: 'Email is required' })} id="email" name="email"
                                            className="line-input w-100" />
                                        {errors.email && <p className='text-danger mb-0'>{errors.email.message}</p>}
                                    </div>
                                    <Label for='projectUrl' className='mt-4'>
                                        Project URL on TikTok*
                                    </Label>
                                    <div>
                                        <input type="text" {...register('projectUrl', { required: 'Project URL is required' })} id="projectUrl" name="projectUrl"
                                            className="line-input w-100" />
                                        {errors.projectUrl && <p className='text-danger mb-0'>{errors.projectUrl.message}</p>}
                                    </div>
                                </div>
                            </Col>
                            <Col lg="1">
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="1">
                            </Col>
                            <Col lg="5">
                                <Label for='projectBudget' className='mt-4'>
                                    Project Budget*
                                </Label>
                                <div>
                                    <input type="text" {...register('projectBudget', { required: 'Project Budget is required' })} id="projectBudget" name="projectBudget"
                                        className="line-input w-100" />
                                        {errors.projectBudget && <p className='text-danger mb-0'>{errors.projectBudget.message}</p>}
                                </div>
                            </Col>
                            <Col lg="5">
                                <div className='d-flex flex-column mt-4'>
                                    <Label for='aboutProject'>
                                        Tell us about your Project
                                    </Label>
                                    <input type="text" {...register('aboutProject', { required: false })} id="aboutProject" name="aboutProject" className="line-input mb-4" />
                                </div>
                            </Col>
                            <Col lg="1">
                            </Col>
                        </Row>

                        <Row>
                            <Col lg="1">
                            </Col>
                            <Col lg="10" className='d-flex justify-content-end align-items-end pb-5 mb-5 '>
                                <FormGroup>

                                    <div className='mt-3 py-2 text-center'>

                                        <Button type='submit' className='btn-submit' disabled={btnSubmitted}>SUBMIT
                                            <span>
                                                <img src={require('../../assets/img/launch_img/arrow-right.png').default} className="img-fluid ml-3" />
                                            </span>
                                        </Button>

                                    </div>
                                </FormGroup>
                            </Col>
                            <Col lg="1">
                            </Col>
                        </Row>
                    </Form>
                </div>
            </section>

        </>
    )
}

export default Pro_Info